<template>
  <v-card elevation="1" :loading="loading" :disabled="loading" class="deals-order-details">
      <div class="details-title">
        <h3>{{ $t('Order details') }}</h3>
      </div>
      <div>
        <div>
          <b>{{ $t('Product ID') }}: </b>
          <router-link v-if="isAdminRoute" :to="{name: 'admin-product', params: {id: orderData.product_id}}">{{ orderData.product_id }}</router-link>
          <router-link v-else :to="{name: 'product', params: {id: orderData.product_id}}">{{ orderData.product_id }}</router-link>
        </div>
        <div>
          <b>{{ $t('Order ID') }}: </b>{{ orderData.id }}
        </div>
        <div v-if="isAdminRoute"><b>{{ $t('Decoded ID') }}: </b>{{orderData.decoded_id}}
        </div>
        <div>
          <b>{{ $t('Order date') }}: </b>{{ orderData.created_at }}
        </div>
        <div v-if="isAdminRoute">
          <b>{{ $t('Buyer') }}: </b><router-link :to="{name: 'user', params: {id: orderData.buyer?orderData.buyer.id:''}}">{{ orderData.buyer?orderData.buyer.name:'' }}</router-link>
        </div>
        <div v-if="isAdminRoute">
          <b>{{ $t('Seller') }}: </b><router-link :to="{name: 'user', params: {id: orderData.seller?orderData.seller.user_id:'', tab: 'store'}}">{{ orderData.seller?orderData.seller.title:'' }}</router-link>
        </div>
        <div>
          <b>{{ $t('Status') }}: </b>
          <v-chip small
            :color="statusColor[status[orderData.status]]"
            :class="`${statusColor[status[orderData.status]]}--text`"
            class="v-chip-light-bg font-weight-semibold"
          >
            {{ status[orderData.status] }}
          </v-chip>
        </div>
      </div>
  </v-card>
</template>

<script>
import {computed} from "@vue/composition-api";
import store from "@/store";
import {useRouter} from "@core/utils";

export default {
  name: "OrderDetails",
  setup() {
    const {route} = useRouter()
    const loading = computed(() => store.state.order.loading)
    const orderData = computed(() => store.state.order.order)
    const isAdminRoute = computed(() => route.value.name === 'admin-order')
    return {
      isAdminRoute,
      loading,
      orderData,
      status: {
        1: 'Paid',
        2: 'Refund Requested',
        3: 'Refund Declined',
        4: 'Refunded',
        5: 'Escalated to Admin',
        6: 'Refund Declined by Admin',
        7: 'Refunded by admin'
      },
      statusColor: {
        /* eslint-disable key-spacing */
        Paid: 'success',
        'Refund Declined': route.value.name.includes('admin') || route.value.name.includes('seller') ? 'success' : 'error',
        'Refund Requested': route.value.name.includes('seller') ? 'error' : 'warning',
        Refunded: route.value.name.includes('admin') || route.value.name.includes('seller') ? 'blue-grey' : 'success',
        'Refund Declined by Admin': route.value.name.includes('admin') || route.value.name.includes('seller') ? 'success' : 'blue-grey',
        'Escalated to Admin': route.value.name.includes('admin') ? 'error' : 'warning',
        'Refunded by admin': route.value.name.includes('admin') || route.value.name.includes('seller') ? 'blue-grey' : 'success',
        /* eslint-enable key-spacing */
      },
    }
  }
}
</script>

<template>
  <v-card elevation="1" :disabled="loading" :loading="loading" class="pb-5 mt-3 deals-order-history">
    <h3 class="pa-4">{{ $t('Order history') }}</h3>
    <v-timeline
        align-top
        dense
        class="py-0 pr-4"
    >
      <v-timeline-item
          v-for="(history, index) in orderData.order_history"
          :key="history.id"
          :color="orderHistory[history.status].color"
          small
      >
        <div>
          <div class="font-weight-normal">
            <strong> {{ $t('Status') }}: <span :class="orderHistory[history.status].color+'--text'">{{
                orderHistory[history.status].title
              }}</span></strong>
          </div>
          <div>{{ history.date }}</div>
          <div v-if="isTimer(history.date_end) && index == 0 && $route.name.includes('admin')" class="deals-expires-date">
            <count-down-timer
              :end="history.date_end"
              color="error"
              :tooltip="getTimerTooltipAdmin(history.status)"
              class="ml-2"
            />
          </div>
          <div v-if="isTimer(history.date_end) && index == 0 && $route.name.includes('seller') && history.status != 3" class="deals-expires-date">
            <count-down-timer
              :end="history.date_end"
              color="error"
              :tooltip="getTimerTooltipSeller(history.status)"
              class="ml-2"
            />
          </div>
          <div v-if="isTimer(history.date_end) && index == 0 && $route.name == 'order'" class="deals-expires-date">
            <count-down-timer
              :end="history.date_end"
              color="error"
              :tooltip="getTimerTooltipBuyer(history.status)"
              class="ml-2"
            />
          </div>
          <div>{{ $t(history.note) }}</div>
          <div v-if="orderData.can_be_escalated && $route.name == 'order' && history.status == 3">
            <span class="body-2 font-weight-thin">Disagree with the seller's decision?</span>
            <v-btn text x-small color="primary"
                   :loading="escalateToAdminLoading"
                   :disabled="escalateToAdminLoading"
                   class="text-capitalize"
                   @click="escalateToAdmin">{{ $t('escalate') }}
            </v-btn>
          </div>
          <div class="row mt-2"
               v-if="$route.name == 'seller-order' && history.status == 2 && orderData.status == 2 ||
                  $route.name == 'admin-order' && history.status == 5 && orderData.status == 5">
            <v-dialog
                v-model="refundApproveDialog"
                persistent
                max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-1 mb-2" v-bind="attrs" v-on="on" small color="success">{{ $t('Approve') }}</v-btn>
              </template>
              <v-card :loading="refundCardLoading" :disabled="refundCardLoading">
                <v-card-title>
                  <span class="text-h5">{{ $t('Approve refund?') }}</span>
                </v-card-title>
                <v-img max-height="300" max-width="300" :src="banner" style="margin: auto"></v-img>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="refundApproveDialog = false"
                  >
                    {{ $t('Close') }}
                  </v-btn>
                  <v-btn
                    color="success darken-1"
                    text
                    @click="acceptRefund()"
                  >
                    {{ $t('Submit') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
                v-model="refundDeclineDialog"
                persistent
                max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-1" v-bind="attrs" v-on="on" small color="error">{{ $t('Decline') }}</v-btn>
              </template>
              <v-card :loading="refundCardLoading" :disabled="refundCardLoading">
                <v-form ref="formRefund" v-model="validRefund" @submit.prevent="rejectRefund">
                  <v-card-title>
                    <span class="text-h5">{{ $t('Decline refund?') }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            outlined
                            name="input"
                            :rules="refundRules"
                            label="Description"
                            v-model="refundNote"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="refundDeclineDialog = false"
                    >
                      {{ $t('Close') }}
                    </v-btn>
                    <v-btn
                      color="success darken-1"
                      text
                      type="submit"
                    >
                      {{ $t('Submit') }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
    <div class="row mt-2" v-if="$route.name == 'admin-order' && orderData.status != 4 && orderData.status != 7">
      <v-dialog
          v-model="refundDirectDialog"
          persistent
          max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-7 my-2 text-uppercase" v-bind="attrs" v-on="on" small color="error">{{ $t('Refund Directly') }}</v-btn>
        </template>
        <v-card :loading="refundDirectLoading" :disabled="refundDirectLoading">
          <v-card-title>
            <span class="text-h5">{{ $t('refund?') }}</span>
          </v-card-title>
          <v-img max-height="300" max-width="300" :src="banner" style="margin: auto"></v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="refundDirectDialog = false"
            >
              {{ $t('Close') }}
            </v-btn>
            <v-btn
              color="success darken-1"
              text
              @click="directRefund()"
            >
              {{ $t('Submit') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import {computed, getCurrentInstance, ref} from "@vue/composition-api/dist/vue-composition-api";
import store from "@/store";
import axios from "@axios";
import router from "@/router";
import {useRouter} from "@core/utils";
import CountDownTimer from "@/views/cabinet/order/order-list/CountDownTimer";

export default {
  name: "orderHistory",
  components: {
    CountDownTimer
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const {route} = useRouter()
    const loading = computed(() => store.state.order.loading)
    const orderData = computed(() => store.state.order.order)
    const refundDeclineDialog = ref(false)
    const refundApproveDialog = ref(false)
    const refundDirectDialog = ref(false)
    const refundNote = ref('')
    const refundRules = [v => !!v || 'Description required', v => v.length > 20 || 'Minimal length of characters is 20']
    const validRefund = ref(true)
    const formRefund = ref(null)
    const refundCardLoading = ref(false)
    const refundDirectLoading = ref(false)
    const getProduct = () => {
      if(route.value.name == 'admin-order'){
        store.dispatch('order/getAdminOrder', {id: router.currentRoute.params.id})
      } else if(route.value.name == 'seller-order')
      {
        store.dispatch('order/getSellerOrder', {id: router.currentRoute.params.id})
      }else {
        store.dispatch('order/getOrder', {id: router.currentRoute.params.id})
      }
    }
    const acceptRefund = () => {
      refundCardLoading.value = true
      let uri = route.value.name == 'admin-order' ? 'admin/accept-refund/' : 'accept-refund/';
      axios.post(uri + orderData.value.id).then(res => {
        vm.$notify({
          group: 'deals',
          title: vm.$t('order refunded'),
          text: '',
          type: 'success'
        })
        refundApproveDialog.value = false
        refundCardLoading.value = false
        getProduct()
      }).catch(err => {
        vm.$notify({
          group: 'deals',
          title: vm.$t('Refund'),
          text: vm.$t('something went wrong'),
          type: 'error'
        })
        refundCardLoading.value = false
      })
    }
    const rejectRefund = () => {
      validRefund.value = formRefund.value[0].validate()
      if (validRefund.value) {
        refundCardLoading.value = true
        let uri = route.value.name == 'admin-order' ? 'admin/reject-refund/' : 'reject-refund/';
        axios.post(uri + orderData.value.id, {note: refundNote.value}).then(res => {
          vm.$notify({
            group: 'deals',
            title: vm.$t('order refund declined'),
            text: '',
            type: 'success'
          })
          refundDeclineDialog.value = false
          refundCardLoading.value = false
          getProduct()
        }).catch(err => {
          vm.$notify({
            group: 'deals',
            title: vm.$t('Refund Decline'),
            text: vm.$t('something went wrong'),
            type: 'error'
          })
          refundCardLoading.value = false
        })
      }
    }
    const escalateToAdminLoading = ref(false)
    const escalateToAdmin = () => {
      escalateToAdminLoading.value = true
      axios.post('escalate-refund-to-admin/' + orderData.value.id).then(res => {
        vm.$notify({
          group: 'deals',
          title: vm.$t('refund escalated to admin'),
          text: '',
          type: 'success'
        })
        getProduct()
        escalateToAdminLoading.value = false
      }).catch(err => {
        vm.$notify({
          group: 'deals',
          title: vm.$t('Refund escalate to admin'),
          text: vm.$t('something went wrong'),
          type: 'error'
        })
        escalateToAdminLoading.value = false
      })
    }
    const directRefund = () => {
      refundDirectLoading.value = true
      axios.post('admin/direct-refund/' + orderData.value.id).then(res => {
        vm.$notify({
          group: 'deals',
          title: vm.$t('order refunded'),
          text: '',
          type: 'success'
        })
        refundDirectDialog.value = false
        refundDirectLoading.value = false
        getProduct()
      }).catch(err => {
        vm.$notify({
          group: 'deals',
          title: vm.$t('Refund'),
          text: vm.$t('something went wrong'),
          type: 'error'
        })
        refundDirectLoading.value = false
      })
    }
    const getTimerTooltipBuyer = (statusId) => {
      switch (statusId) {
        //Refund requested
        case 2:
          return 'Seller will make a decision on your application within 3 days. Otherwise the refund will be issued automatically'
        //Declined by seller
        case 3:
          return 'You can appeal the seller\'s decision within ' + orderData.value.refund_period + ' days'
        //Escalated to admin
        case 5:
          return 'Administration will make a decision within ' + orderData.value.refund_expired_period + ' days'
      }
    }
    const getTimerTooltipSeller = (statusId) => {
      switch (statusId) {
        //Refund requested
        case 2:
          return 'You have ' + orderData.value.refund_expired_period + ' days to make a decision. Otherwise the order will be refunded automatically.'
        //Escalated to admin
        case 5:
          return 'Administration will resolve your dispute within ' + orderData.value.refund_expired_period + ' days'
      }
    }
    const getTimerTooltipAdmin = (statusId) => {
      //Escalated to Admin
      if(statusId == 5) {
        return 'You need to resolve the dispute within ' + orderData.value.refund_expired_period + ' days'
      }
    }
    const isTimer = (end) => Math.trunc((new Date()).getTime() / 1000) < Math.trunc(Date.parse(end) / 1000)
    return {
      getTimerTooltipBuyer,
      getTimerTooltipSeller,
      getTimerTooltipAdmin,
      isTimer,
      refundNote,
      refundRules,
      validRefund,
      formRefund,
      refundCardLoading,
      refundDeclineDialog,
      loading,
      orderData,
      refundApproveDialog,
      refundDirectDialog,
      refundDirectLoading,
      rejectRefund,
      acceptRefund,
      escalateToAdmin,
      directRefund,
      escalateToAdminLoading,
      orderHistory: [
        {
          color: 'warning',
          title: 'Order in progress'
        },
        {
          color: 'success',
          title: 'Order placed'
        },
        {
          color: route.value.name.includes('seller') ? 'error' : 'warning',
          title: 'Refund requested'
        },
        {
          color: route.value.name.includes('admin') || route.value.name.includes('seller') ? 'success' : 'error',
          title: 'Declined by seller'
        },
        {
          color: route.value.name.includes('admin') || route.value.name.includes('seller') ? 'rgba(121, 118, 126, 1)' : 'success',
          title: 'Refunded'
        },
        {
          color: route.value.name.includes('admin') ? 'error' : 'warning',
          title: 'Escalated to admin'
        },
        {
          color: route.value.name.includes('admin') || route.value.name.includes('seller') ? 'success' : 'rgba(121, 118, 126, 1)',
          title: 'Declined by admin'
        },
        {
          color: route.value.name.includes('admin') || route.value.name.includes('seller') ? 'rgba(121, 118, 126, 1)' : 'success',
          title: 'Refunded by admin'
        }
      ],
      banner: require('@/assets/images/app/business-man-depressed-stressed-watching-decrease-graph-stock-financial-trade-market-diagram_1150-39760 1.png')
    }
  }
}
</script>

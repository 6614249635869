<template>
  <v-card class="mb-6" elevation="1" :loading="productLoading" :disabled="productLoading">
    <v-row v-if="productData.status !== 4 && productData.status !== 7 || $route.name === 'admin-order'">
      <v-col cols="12" sm="4">
        <figure class="ml-3">
          <v-img width="100%" max-height="300" :src="productData.image" class="product-view-img"/>
        </figure>
      </v-col>
      <v-col cols="12" sm="8">
        <v-list>
          <v-list-item>
            <h3>{{ productData.title }}</h3>
          </v-list-item>
          <v-list-item>
            <v-list-item-title><b>{{ $t('ASIN:') }}</b><span><a :href="productData.product_url" target="_blank">{{ productData.asin }}</a></span>&nbsp;
              <v-icon class="cursor-pointer mb-1" small color="primary" @click="copyText(productData.asin)">
                {{ mdiClipboardMultipleOutline }}
              </v-icon>
              <v-icon class="cursor-pointer mb-1" small color="primary" @click="copyText(productData.product_url)">
                {{ mdiLinkVariant }}
              </v-icon>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title><b>{{ $t('Source:') }}</b> <a
                :href="productData.source_link" target="_blank">{{ productData.source_link }}</a>&nbsp;
              <v-icon class="cursor-pointer mb-1" small color="primary" @click="copyText(productData.source_link)">
                {{ mdiClipboardMultipleOutline }}
              </v-icon>
            </v-list-item-title>
          </v-list-item>
          <v-list-item two-line v-if="productData.vendor_note">
            <v-list-item-content>
              <v-list-item-title><b>{{ $t('Message for buyer:') }}</b></v-list-item-title>
              <span class="pa-2" :class="$vuetify.theme.isDark ? 'vendor-note-dark' : 'vendor-note'">{{ productData.vendor_note }}</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <div v-else class="pa-1"><v-img :src="require('@/assets/images/app/refunded.png')"></v-img></div>
    <v-snackbar
        v-model="clipboardSnackbar"
        timeout="2000"
    >
      {{ $t('Copied to clipboard') }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="clipboardSnackbar = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import {computed, ref} from "@vue/composition-api/dist/vue-composition-api";
import store from "@/store";
import {mdiClipboardMultipleOutline, mdiLinkVariant} from "@mdi/js";

export default {
  name: "ProductData",
  setup(){
    const clipboardSnackbar = ref(false)
    const productData = computed(() => store.state.order.order)
    const productLoading = computed(() => store.state.order.loading)
    const copyText = (text) => {
      navigator.clipboard.writeText(text);
      clipboardSnackbar.value = true
    }
    return {
      clipboardSnackbar,
      mdiClipboardMultipleOutline,
      mdiLinkVariant,
      copyText,
      productData,
      productLoading,
    }
  }
}
</script>